// This helper is a mofdified replica of ko-depended logger.
// The old logger will be removed after all usages are replaced.
import toastr from '@publicsafety/toastr';
import { ApplicationError } from '@/VueCore/services/clients/applicationError';
import resourceHelper from '@/Utils/resourceHelper';
import config from '@/config';

class Logger {
  constructor() {
    toastr.options = {
      closeButton: true,
      progressBar: true,
      newestOnTop: true,
      timeOut: 4000
    };
  }

  /**
   * Displays an error to the user and logs to the console
   * @param {string} resourceKey
   * @param {string|null} [debugMessage] Message to be logged for debugging / internal purposes
   * @param {*} [error]
   */
  public error(resourceKey: string, debugMessage?: string | null, error?: ApplicationError): void {
    let message = resourceHelper.getString(resourceKey);
    const errorDetails = createDetails(resourceKey, message, debugMessage, error);

    if (config.showErrorDebugMessage && debugMessage) {
      message += ' ' + debugMessage;
    }

    console.error(errorDetails);
    toastr.error(message, null, { timeOut: 10000 });
  }

  /**
   * Displays an error to the user without a timeout (user has to dismiss it) and logs to the console
   * @param {string} resourceKey
   * @param {string} [debugMessage] Message to be logged for debugging / internal purposes
   * @param {*} [error]
   */
  public errorWithoutTimeout(resourceKey: string, debugMessage?: string, error?: ApplicationError): void {
    let message = resourceHelper.getString(resourceKey);
    const errorDetails = createDetails(resourceKey, message, debugMessage, error);

    if (config.showErrorDebugMessage && debugMessage) {
      message += ' ' + debugMessage;
    }

    console.error(errorDetails);
    toastr.error(message, null, { timeOut: null });
  }

  /**
   * Logs a warning
   * @param {string} resourceKey
   * @param {string} [debugMessage] Message to be logged for debugging / internal purposes
   * @param {ApplicationError} [error]
   */
  public warning(resourceKey: string, debugMessage?: string, error?: ApplicationError): void {
    let message = resourceHelper.getString(resourceKey);
    const warningDetails = createDetails(resourceKey, message, debugMessage, error);

    if (config.showErrorDebugMessage && debugMessage) {
      message += ' ' + debugMessage;
    }

    console.warn(warningDetails);
    toastr.warning(message, null, { timeOut: 10000 });
  }

  /**
   * Logs an untranslated warning
   * @param {string} message
   * @param {string} [debugMessage] Message to be logged for debugging / internal purposes
   * @param {ApplicationError} [error]
   * @param {Boolean} [isInfinite] Do not hide toastr once user close it
   */
  public untranslatedWarning(message: string, debugMessage?: string, error?: ApplicationError, isInfinite: boolean = false): void {
    const warningDetails = createDetails(message, message, debugMessage, error);

    if (config.showErrorDebugMessage && debugMessage) {
      message += ' ' + debugMessage;
    }

    console.warn(warningDetails);
    const options: any = {
      timeOut: isInfinite ? 0 : 10000
    };

    if (isInfinite) {
      options.extendedTimeOut = 0;
    }
    toastr.warning(message, null, options);
  }

  /**
   * Logs a debug message to the console for a technical / developer audience
   * @param {string} debugMessage
   * @param {*} [optionalParams]
   */
  public debug(debugMessage: string, optionalParams?: any): void {
    if (config.loggingSettings.handleDebug) {
      if (optionalParams) {
        console.debug(debugMessage, optionalParams);
      } else {
        console.debug(debugMessage);
      }
    }
  }

  public success(resourceKey: string): void {
    const message = resourceHelper.getString(resourceKey);
    toastr.success(message);
  }

  public info(resourceKey: string): void {
    const message = resourceHelper.getString(resourceKey);
    toastr.info(message);
  }

  /**
   * Logs an error to the console and displays an untranslated friendly message to the user. This method
   * must only be used for errors that occur during SPA startup when it's not possible to determine the
   * language for the user and therefore a translated friendly message can't be displayed.
   *
   * NOTE: Only use from within the "Loaders" scripts where resource data has yet to be loaded.
   *
   * @param {string} friendlyMessage
   * @param {string|null} debugMessage
   * @param {ApplicationError} [error]
   */

  public logAndThrowUntranslatedError(friendlyMessage: string, debugMessage?: string | null, error?: ApplicationError): void {

    // If the error has already been handled then nothing to do here
    if (error?.isLogged) {
      return;
    }

    const resourceKey = 'None: Untranslated error';
    const errorDetails = createDetails(resourceKey, friendlyMessage, debugMessage, error);
    let message = friendlyMessage;

    if (config.showErrorDebugMessage && debugMessage) {
      message += ' ' + debugMessage;
    }

    console.error(errorDetails);
    toastr.error(message, null, { timeOut: null }); // Don't automatically hide the toastr, user must close.

    if (error) {
      error.isLogged = true;
    }
    throw error;
  }

  public logForbiddenAccess(): void {
    const resourceKey = 'ForbiddenAccessErrorMessage';
    let message = 'You have attempted to access content or perform an action that you are currently forbidden access to.';

    if (resourceHelper.hasResourceData()) {
      message = resourceHelper.getString(resourceKey);
    }

    const warningDetails = createDetails(resourceKey, message);

    console.warn(warningDetails);
    toastr.warning(message, null, { timeOut: 0, preventDuplicates: true });
  }

  public logTimeout(): void {
    const resourceKey = 'AjaxTimeOutErrorMessage';
    let message = 'The server took too long to respond with data and a timeout occurred. Please try again later.';

    if (resourceHelper.hasResourceData()) {
      message = resourceHelper.getString(resourceKey);
    }

    const warningDetails = createDetails(resourceKey, message);

    console.warn(warningDetails);
    toastr.warning(message, null, { timeOut: 10000, preventDuplicates: true });
  }
}

function createDetails(resourceKey: string, friendlyMessage: string, debugMessage?: string | null, error?: ApplicationError) {
  return {
    resourceKey: resourceKey,
    friendlyMessage: friendlyMessage,
    debugMessage: debugMessage,
    error: error
  };
}

export default new Logger();
