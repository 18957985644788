import { FileUploadEngine, UploadOptions } from '@publicsafety/fileupload';
import { FileUploadEvent, BaseEventData, FileProgressEventData } from '@publicsafety/fileupload';

import { FileUploadEventStore } from '@publicsafety/fileupload';
import uploadManager from '@/Utils/uploadManager';
import { useContextDataStore } from '@/VueCore/stores/contextDataStore';

export default class FlowWrapper {
  static init = () => {
    const uploadOptions = new UploadOptions();
    const contextData = useContextDataStore();
    uploadOptions.fileUploadServiceBaseUrl = contextData.portalSettings.fileUploadServiceRootUrl;
    uploadOptions.simultaneousChunkUploads = contextData.portalSettings.maximumConcurrentUploads;
    uploadOptions.chunkSize = contextData.portalSettings.maximumChunkSize;

    FileUploadEngine.init(uploadOptions);

    // subscribe events
    uploadManager.uploads.subscribe((changes: any[]) => {
      changes.forEach(change => {
        const upload = change.value;

        if (change.status === 'added') {
          FlowWrapper.addFileUpload(upload);
        }
      });
    }, null, 'arrayChange');

    // Prevent the browser default drag and drop behavior
    document.addEventListener('dragover', e => {
      e.preventDefault();
    });

    document.addEventListener('drop', e => {
      e.preventDefault();
    });

    FileUploadEventStore.subscribe({
      eventId: FileUploadEvent.FileStarted,
      eventHandler: (eventData: BaseEventData) => {
        uploadManager.setUploadStarted(eventData);
      }
    });

    FileUploadEventStore.subscribe({
      eventId: FileUploadEvent.FileSuccess,
      eventHandler: (eventData: BaseEventData) => {
        uploadManager.setUploadComplete(eventData);
      }
    });

    FileUploadEventStore.subscribe({
      eventId: FileUploadEvent.FileError,
      eventHandler: (eventData: BaseEventData) => {
        uploadManager.setUploadFailed(eventData);
      }
    });

    FileUploadEventStore.subscribe({
      eventId: FileUploadEvent.FileProgress,
      eventHandler: (eventData: FileProgressEventData) => {
        uploadManager.updateUploadProgress(eventData.id, eventData.percentage);
        uploadManager.setAllUploadsProgress(eventData.overallPercentage);
      }
    });
  };

  static addFileUpload = (upload: any) => {
    const fileUploads = [{
      id: upload.uploadId,
      uploadToken: upload.uploadToken,
      nativeFile: upload.nativeBrowserFile
    }];
    FileUploadEngine.addFileUploads(fileUploads);
  };

  static cancelFileUpload = (nativeFile: File) => {
    FileUploadEngine.cancelFileUpload(nativeFile);
  };
}