import { UploadType } from '@/Types/Enums/uploadType';
import { UploadContentType } from '@/Types/Enums/uploadContentType';
import resourceHelper from '@/Utils/resourceHelper';
import { FileUploadType } from '@/VueComponents/FileUploadSelector/enums/fileUploadType';

export default class UploadTypeResolver {
  static getFromFileUploadType(type: FileUploadType): UploadType {
    // UploadType is an extended version of the FileUploadType enum
    return type as UploadType;
  }

  static getTypeFromNativeFile(file: File) {
    const isFile = file.isFile;
    if (isFile && file.webkitRelativePath) {
      return UploadType.folderFile;
    }

    if (isFile && !file.webkitRelativePath) {
      return UploadType.file;
    }

    const isZip = file.isZip;
    switch (file.uploadContentType) {
      case UploadContentType.generalContent:
        return isZip ? UploadType.zipGeneralContent : UploadType.folderFile;
      case UploadContentType.cctv:
        return isZip ? UploadType.zipCctv : UploadType.cctvFile;
      case UploadContentType.deviceBackup:
        return isZip ? UploadType.zipDeviceBackup : UploadType.deviceBackupFile;
    }
  }

  static localizeUploadType(uploadType: UploadType, isFolder: boolean): string {
    if (uploadType === UploadType.cctv) {
      return resourceHelper.getString('CCTVFolderExport');
    }

    // Return caption only for the folder item, so the files displayed inside the folder does not have it
    if (uploadType === UploadType.cctvFile && isFolder) {
      return resourceHelper.getString('CCTVFolderExport');
    }

    if (uploadType === UploadType.zipCctv) {
      return resourceHelper.getString('ZipCctv');
    }

    if (uploadType === UploadType.deviceBackup) {
      return resourceHelper.getString('DeviceBackup');
    }

    // Return caption only for the folder item, so the files displayed inside the folder does not have it
    if (uploadType === UploadType.deviceBackupFile && isFolder) {
      return resourceHelper.getString('DeviceBackup');
    }

    if (uploadType === UploadType.zipDeviceBackup) {
      return resourceHelper.getString('ZipDeviceBackup');
    }

    // Return caption only for the folder item, so the files displayed inside the folder does not have it
    if (uploadType === UploadType.generalContentFile && isFolder) {
      return resourceHelper.getString('GeneralContentFolder');
    }

    if (uploadType === UploadType.generalContent) {
      return resourceHelper.getString('GeneralContentFolder');
    }

    if (uploadType === UploadType.zipGeneralContent) {
      return resourceHelper.getString('ZipGeneralContent');
    }

    return '';
  }
}