import UploadModel from '@/Models/uploadModel';
import PartnerZipFolderUploadModel from '@/Models/partnerZipFolderUploadModel';
import { UploadType } from '@/Types/Enums/uploadType';
import PartnerUploadModelAdapter from '@/Utils/partnerUploadModelAdapter';

const singleFileUploadTypes = [
  UploadType.file,
  UploadType.folderFile,
  UploadType.deviceBackup,
  UploadType.generalContent,
  UploadType.cctv,
  UploadType.zipGeneralContent,
  UploadType.zipCctv,
  UploadType.zipDeviceBackup
];

const folderFileUploadTypes = [
  UploadType.deviceBackupFile,
  UploadType.cctvFile,
  UploadType.generalContentFile
];

export function getFolderUploads(uploads: UploadModel[]): PartnerZipFolderUploadModel[] {
  const folders: PartnerZipFolderUploadModel[] = [];

  for (const type of folderFileUploadTypes) {
    folders.push(...mapFolderUploads(uploads, type));

  }

  return folders;
}

export function mapFolderUploads(uploads: UploadModel[], requiredType: UploadType): PartnerZipFolderUploadModel[] {
  const folders = uploads.filter(upload => {
    return upload.type === requiredType;
  }).reduce(function (rv: any, x: UploadModel) {
    (rv[x.rootFolderName] = rv[x.rootFolderName] || []).push(x);
    return rv;
  }, {});

  const models = [];
  for (const key in folders) {
    if (Object.prototype.hasOwnProperty.call(folders, key)) {
      const partnerUploads = folders[key].map((u: UploadModel) => PartnerUploadModelAdapter.getPartnerUploadModel(u));
      const folder = new PartnerZipFolderUploadModel(partnerUploads);
      models.push(folder);
    }
  }
  return models;
}

export function getSingleFileUploads(uploads: UploadModel[]) {
  return uploads.filter(upload => singleFileUploadTypes.includes(upload.type))
    .map(u => PartnerUploadModelAdapter.getPartnerUploadModel(u));
}