import { useContextDataStore } from '@/VueCore/stores/contextDataStore';
import config from '@/config';
/**
  * Module to return translated text based on the content in the server-side resource files.
 * The resource file is "50 Cross-Cutting\53 Resources\BusinessPortal.Resources.ClientMessages".
 */
class ResourceHelper {
  /**
   * Gets a localized string for the given resource key
   * @param {string} resourceKey
   * @param {Record<string, string>} [replaceObj]
   * * @return {string}
   */

  public getString(resourceKey: string, replaceObj?: Record<string, string>): string {
    const contextData = useContextDataStore();
    if (!contextData.resourceData) {
      throw new Error('The contextData module does not contain resourceData');
    }

    let value = contextData.resourceData[resourceKey];

    if (value) {
      if (replaceObj) {
        for (const replaceKey in replaceObj) {
          if (!Object.prototype.hasOwnProperty.call(replaceObj, replaceKey)) {
            continue;
          }

          const replacement = replaceObj[replaceKey];
          value = value.replace(`{${replaceKey}}`, replacement);
        }
      }

      if (config.wrapTranslatedTextInBrackets) {
        return '|' + value + '|';
      }

      return value;
    }

    return '[!' + resourceKey + '!]';
  }

  /**
   * When the SPA initially loads, AJAX requests are made to retrieve the authentication settings and the
   * user data (which includes the user's language). Only after these AJAX requests is the resource data
   * retrieved. If there is code that might use the resource helper before the SPA has loaded / the
   * resource data is retrieved then this method should be used to determine if the resource data exists
   * before attempting to retrieve a translated string.
   * @return {boolean}
   */
  public hasResourceData(): boolean {
    const contextData = useContextDataStore();
    return !!contextData.resourceData; // Convert truthy value to true
  }
}

export default new ResourceHelper();