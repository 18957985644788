import FileModel from '@/VueComponents/FileUploadSelector/models/fileModel';
import RequestUploadValidation from '@/Validation/requestUploadValidation';

export default class UploadSelectorUtils {
  public static splitRegularAndZipFiles(files: FileModel[]) {
    const zipFiles = [];
    const nonZipFiles = [];
    for (const file of files) {
      if (RequestUploadValidation.isFileZip(file.name)) {
        zipFiles.push(file);
      } else {
        nonZipFiles.push(file);
      }
    }

    return { zipFiles, nonZipFiles };
  }
}