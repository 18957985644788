import Oidc from 'oidc-client';
import logger from '@/Utils/loggerVue';
import redirectHelper from '@/Utils/redirectHelper';
import { LanguageDetailsSource, LanguageDetails } from '@/Types/languageDetails';

/**
 * Module to act as a wrapper around the oidc-token-manager.
 */
class TokenManager {
  private userManager: Oidc.UserManager | null = null;
  private user: Oidc.User | null = null;

  public initAsync(tokenManagerSettings: Oidc.UserManagerSettings): Promise<LanguageDetails | void> {
    this.userManager = new Oidc.UserManager(tokenManagerSettings);

    return this.userManager.getUser()
      .then(theUser => {
        this.user = theUser; // Is null if the user is not authenticated

        const locale = this.user?.profile?.locale ?? null;

        if (locale) {
          console.debug(`The auth token contains the locale '${locale}'"`);
        } else {
          console.debug(`The auth token didn't contain a locale claim`);
        }

        return {
          languageCode: locale,
          updatedDate: this.user?.profile?.auth_time ?
            new Date(this.user.profile.auth_time * 1000) : // Convert epoch in seconds to milliseconds
            new Date(0), // Epoch 0 / milliseconds since January 1, 1970
          source: LanguageDetailsSource.AuthToken
        };

      })
      .catch(err => {
        console.error(err);
      });
  }

  public validTokenExists(): boolean {
    return !!this.user && !this.user.expired;
  }

  public getAccessToken(): string {
    if (!this.userManager) {
      throw new Error('An attempt was made to retrieve the access token before the token manager has been initialized');
    }

    return this.user!.access_token;
  }

  public loginRedirect(): void {
    this.userManager!.signinRedirect()
      .then(() => {
        console.info('signinRedirect done');
      })
      .catch(err => {
        logger.logAndThrowUntranslatedError(
          'An error occurred loading the site. Please try again later.',
          'Unable to use the token manager to redirect to the authentication page. ',
          err
        );
      });
  }

  public logoutRedirect(): void {
    this.userManager!.signoutRedirect()
      .then(resp => {
        console.info('signed out', resp);
        sessionStorage.clear();
      })
      .catch(err => {
        console.error(err);
      });
  }

  /**
   * When we have a token in the URL after authentication for access to the portal is complete, pass the
   * OIDC client the token and redirect to the main portal page.
   */
  public processLoginTokenAfterCallback(): void {
    new Oidc.UserManager().signinRedirectCallback()
      .then(user => {
        console.info('Processed the authentication token correctly. Redirecting to the main portal.', user);
        redirectHelper.postPortalAuthenticationRedirect();
      });
  }

  /**
   * When we have a token in the URL after authentication for access to the registration page is complete,
   * pass the OIDC client the token and redirect to the main registration page.
   */
  public processRegistrationTokenAfterCallback(): void {
    new Oidc.UserManager().signinRedirectCallback()
      .then(user => {
        console.info('Processed the authentication token correctly. Redirecting to the registration screen.', user);
        redirectHelper.postRegistrationAuthenticationRedirect();
      });
  }

  public processTokenCallbackSilent(): void {
    console.warn('Silent token renewal is not currently supported');
  }
}

export default new TokenManager();
