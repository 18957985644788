import logger from '@/Utils/loggerVue';
import { ApplicationError } from '@/VueCore/services/clients/applicationError';

/**
 * Module to catch all unhandled JavaScript errors and log them to the console.
 */
class WindowErrorHandler {
  constructor() {
    window.addEventListener('error', this.handleError);
  }

  private handleError(evt: ErrorEvent): void {
    const resourceKey = 'UnhandledError';
    const debugMessage = `Unhandled error [caught in windowErrorHandler]: '${evt.message}' from ${evt.filename}, Line: ${evt.lineno}`;

    console.error(debugMessage);
    console.error('Please review the error and handle this at source / not rely on the windowErrorHandler');
    const applicationError = new ApplicationError(evt);
    logger.error(resourceKey, debugMessage, applicationError);
    evt.preventDefault();
  }
}

export default new WindowErrorHandler();